






























































































































































































































































import { Component, Vue } from "vue-property-decorator";
import LotService from "@/api/lots";
import CatalogService from "@/api/catalogs";
import { mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters("UserModule", {
      storage: "getStorage"
    })
  }
})
export default class Agregar extends Vue {
  isFilterToggled = true;
  isHovering = false;
  selected = {} as any;
  isModalOpen = false;
  lot: any[] = [];
  average: any = false;
  ton = 0;
  arpillas = 0;
  storage!: any;
  filtersActive: any = {
    price: undefined,
    client: undefined,
    quality_min: undefined,
    quality_max: undefined,
    container: undefined
  };
  manualQuality: number | undefined = undefined;
  filters: any = {
    prices: [],
    clients: [],
    containers: []
  };
  checkedItemData = [];
  data: any[] = [];
  currentLot = [];

  get routeName() {
    return this.$route.name;
  }

  changePrice() {
    this.checkedItemData = [];
    this.loadItemsData();
  }

  get totalWeight(): number {
    return this.checkedItemData.reduce((sum: number, current: any) => {
      return sum + current.weight;
    }, 0);
  }

  deleteLotItem(index: number): void {
    this.checkedItemData.splice(index, 1);
  }

  createLot() {
    if (this.totalWeight == 0) {
      this.$buefy.notification.open({
        message: "El lote no puede pesar 0 kg",
        type: "is-danger"
      });
      return;
    }
    const data = {
      storage_id: this.storage.id,
      items: this.checkedItemData.map((x: any) => x.id),
      quality:
        this.manualQuality ??
        this.averageCalc(this.checkedItemData.map((x: any) => x.quality)),
      average: this.average === "manual" ? true : this.average,
      manual: this.average === "manual"
    };
    this.$buefy.dialog.confirm({
      message: `¿Crear lote ${data.average ? "Promediado" : "Ponderado"}?`,
      confirmText: "Crear",
      cancelText: "Cancelar",
      onConfirm: () => {
        LotService.create(data).then(() => {
          this.$buefy.notification.open({
            message: "Se ha creado el lote",
            type: "is-success"
          });
          LotService.getAll(this.storage.id).then(response => {
            this.$store.dispatch("LotModule/setLots", response.data.data);
            this.$router.push({ name: "Lotes" });
          });
        });
      }
    });
  }

  editLot() {
    LotService.update(parseInt(this.$route.params.lotId), {
      items: this.checkedItemData.map((x: any) => x.id),
      items_data_selected: [],
      quality: this.manualQuality,
      average: this.average === "manual" ? true : this.average,
      manual: this.average === "manual"
    }).then(() => {
      this.$buefy.notification.open({
        message: "Se ha actualizado el lote",
        type: "is-success"
      });
      LotService.getAll(this.storage.id).then(response => {
        this.$store.dispatch("LotModule/setLots", response.data.data);
        this.$router.push({ name: "Lotes" });
      });
    });
  }

  averageCalc(array: any[]) {
    return array.reduce((a: any, b: any) => a + b) / array.length;
  }

  loadItemsData() {
    if (!this.filtersActive.price) {
      this.$buefy.notification.open({
        message: "Necesitas seleccionar un precio primero",
        type: "is-danger"
      });
      return;
    }
    const filters = JSON.parse(JSON.stringify(this.filtersActive));
    Object.keys(filters).forEach(
      (key: any) => filters[key] === undefined && delete filters[key]
    );
    LotService.getItemsReady(
      this.storage.id,
      new URLSearchParams(filters).toString(),
      "item_data.id, item_data.humidity, item_data.quality, c.name, c.last_name, item_data.weight, item_data.entry_date"
    ).then(response => {
      if (this.currentLot.length > 0) {
        this.data = [...this.currentLot, ...response.data];
      } else {
        this.data = response.data;
      }
    });
  }
  mounted() {
    LotService.getPrices(this.storage.id).then(response => {
      this.filters.prices = response.data;
      if (this.routeName == "EditarLote") {
        LotService.get(parseInt(this.$route.params.lotId)).then(response => {
          if (
            !this.filters.prices.find(
              (x: any) => x.price == response.data.items[0].price
            )
          ) {
            this.filters.prices.push({ price: response.data.items[0].price });
          }
          this.filtersActive.price = response.data.items[0].price;
          this.currentLot = response.data.items;
          this.checkedItemData = this.currentLot;
          this.average = response.data.average;
          this.loadItemsData();
        });
      }
    });
    LotService.getClients(this.storage.id).then(response => {
      this.filters.clients = response.data;
    });
    CatalogService.getStackTypes().then(response => {
      this.filters.containers = response.data;
    });
  }
}
